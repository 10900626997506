import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import MainPage from "./pages/main-page";

import { useStore } from "./helpers/store";
import { LoadingOutlined } from "@ant-design/icons";
import { Suspense, useEffect, useState } from "react";
import configApp from "./config.json";
import ScreenSizeProhibitedPage from "./pages/screen-size-prohibited-page";
import { translate } from "./common/common";
import TranslateServices from "./services/api-translate-services";
import { ICTranslateResponse } from "./types/ic-translate-response";
import React, { lazy } from "react";

const App = () => {
  const user = useStore((state) => state.user);
  const setLanguage = useStore((state) => state.setLanguage);
  const [translateInfo, setTranslateInfo] = useState<ICTranslateResponse[]>([]);
  const setLoading = useStore((state) => state.setLoading);

  function checkUser() {
    return !!user?.token;
  }

  useEffect(() => {
    setTheme();
  }, []);

  function setTheme() {
    let ConfigApp = (key) => configApp[process.env.REACT_APP_ID][key];

    let title = ConfigApp("title");
    document.title = title;

    let favicon = ConfigApp("favicon");
    let link = document.querySelector('link[rel="icon"]');
    link.setAttribute("href", favicon);

    let theme = ConfigApp("theme");
    document.documentElement.style.setProperty(
      "--var-font-primary",
      "https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap"
    );
    document.documentElement.style.setProperty(
      "--var-color-primary",
      theme["color-primary"]
    );
    document.documentElement.style.setProperty(
      "--var-color-primary-dark",
      theme["color-primary-dark"]
    );
    document.documentElement.style.setProperty(
      "--var-color-primary-light",
      theme["color-primary-light"]
    );
    document.documentElement.style.setProperty(
      "--var-color-white",
      theme["color-white"]
    );
    document.documentElement.style.setProperty(
      "--var-color-black",
      theme["color-black"]
    );
    document.documentElement.style.setProperty(
      "--var-color-text-primary",
      theme["color-text-primary"]
    );
  }

  const LoginPage = lazy(() => import("./pages/login-page"));
  const MainPage = lazy(() => import("./pages/main-page"));
  const SummaryPage = lazy(() => import("./pages/summary-page"));
  const SuccessPage = lazy(() => import("./pages/success-page"));

  return (
    <Suspense>
      <Routes>
        <Route path="/:icAppToken" element={<LoginPage />} />
        <Route path="/" element={!checkUser() ? <LoginPage /> : <MainPage />} />
        <Route
          path="/summary"
          element={
            checkUser() ? <SummaryPage /> : <Navigate replace to={"/"} />
          }
        />
        <Route
          path="/success"
          element={
            checkUser() ? <SuccessPage /> : <Navigate replace to={"/"} />
          }
        />
        <Route path="*" element={<Navigate replace to={"/"} />} />
      </Routes>
    </Suspense>
  );
};

export default App;
